<template>
  <!-- 培训记录详情 -->
  <div class="trainingDetails">
    <van-icon name="arrow-left"
              color="#fff"
              @click="comeBack" />
    <img src="@/assets/img/trainingDetails.png"
         alt=""
         class="headerImg" />
    <div class="middle">
      <van-cell class="first_cell"
                title="标准固化模型"
                label="讲师：卞耕方T"
                value="现场培训" />
      <van-cell class="last_cell"
                title="三楼研发部"
                value="2021-05-20 12:32" />
    </div>
    <van-divider />
    <div class="footer">
      <p class="title">标准固化模型</p>
      <p class="content">
        没有规矩不成方圆，企业是一个系统，为了完
        成共同的目标任务而组织在一起，由产销人发财
        等多个模块多个岗位组成，基本工作就是围绕如
        何做好产品，卖好产品而展开的。如果没有标准，
        大家各行其是，最终的管理效率和管理结果一定
        是混乱的。所以，企业管理必须按照统一的标准
        化的管理流程或制度来运作。
        固化，就是指流程或制度等标准化的管理体系
        建立后，企业的各部门各岗位宣贯遵守执行，不
        能违背管理标准，让工作标准成为大家的习惯，
        让大家的工作习惯符合标准。
      </p>
      <van-divider />
      <p class="result">{{result}}</p>
    </div>
  </div>

</template>

<script>
export default {
  data () {
    return {
      result: ''
    }
  },
  methods: {
    comeBack () {
      this.$router.push({ path: '/trainingRecord', query: { fromType: this.$route.query.fromType } })
    }
  },
  created () {
    if (this.$route.query.active == 0)
    {
      this.result = '时间未到/等待培训'
    } else if (this.$route.query.active == 1)
    {
      this.result = '已完成'
    }
  }
}
</script>

<style lang="less" scoped>
.trainingDetails {
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  // background: #eceaea;
  background: #ffffff;
  .van-icon {
    position: absolute;
    top: 50px;
    left: 30px;
    font-size: 38px;
  }
  .headerImg {
    height: 472px;
  }
  .middle {
    .first_cell {
      .van-cell__title {
        font-size: 40px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        line-height: 68px;
        color: #333333;
      }
      .van-cell__label {
        font-size: 32px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 54px;
        color: #666666;
      }
      .van-cell__value {
        font-size: 28px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 48px;
        color: #999999;
      }
    }
    .first_cell::after {
      border-bottom: unset;
    }
    .last_cell {
      .van-cell__title {
        font-size: 32px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 54px;
        color: #333333;
      }
      .van-cell__value {
        font-size: 28px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 48px;
        color: #666666;
      }
    }
  }
  .van-divider {
    padding: 0 30px;
  }
  .footer {
    .title {
      text-align: center;
      font-size: 36px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      line-height: 62px;
      color: #333333;
    }
    .content {
      padding: 0 30px;
      text-indent: 2em;
      font-size: 32px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 54px;
      color: #333333;
    }
    .result {
      text-align: center;
      font-size: 40px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 68px;
      color: #666666;
    }
  }
}
</style>